import { Avatar } from "@material-ui/core";
import Carousel from "react-grid-carousel";

import { Circle } from "../../styles/util/icons";

import {
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const testimonials = [
  {
    title: "Beginner Friendly Website",
    text: `I learned about cryptocurrency earlier this year when I stumbled on Vikkymore’s baby company, Vimepay, and it has been a seamless experience so far. Trading on the platform has been stress free. I am definitely stuck with them`,
    testisfierCompany: "VimePay User.",
    testifier: "Temidayo Oyewole.",
  },
  {
    title: "Great Investment Portfolio",
    text: `Vikkymore has opened my eyes to several investment opportunities. Thanks to the company, I can now sleep and let my money work for me.`,
    testisfierCompany: "CEO at Penstar Limited.",
    testifier: "Oluwatoni Temowo.",
  },
  {
    title: "Beginner Friendly Website",
    text: `I learned about cryptocurrency earlier this year when I stumbled on Vikkymore’s baby company, Vimepay, and it has been a seamless experience so far. Trading on the platform has been stress free. I am definitely stuck with them`,
    testisfierCompany: "Content Strategist at Purple Magazine.",
    testifier: "Tabitha Oyewole.",
  },
  {
    title: "Timely And Selfless Support",
    text: `Vikkymore Foundation visited us at a time we needed it most. We appreciate them for the gifts and the time they spent with the kids. May God continue to uphold them.`,
    testisfierCompany: "Representative, Kent Homes. Ibadan.",
    testifier: "Anonymous",
  },
  {
    title: "Best Delivery Service.",
    text: `I like your service, that’s why I always come back. Still the best delivery I have been using.`,
    testisfierCompany: "Aby’s Place, DDXPRESS Customer.",
    testifier: "Anonymous",
  },
  {
    title: "Best Delivery Service.",
    text: `Vikkymore is so trustworthy and honest.`,
    testisfierCompany: "Vikkymore Exchange Customer.",
    testifier: "Fred Adekunle.",
  },
];

const AboutUsTestimonials = () => {
  return (
    <>
      <MainSection
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        background={"rgba(242, 145, 27, 0.3)"}
        top={5}
      >
        <div style={{ height: "100%", padding: "0.1px" }}>
          <TitleText top={1} center heavy>
            Our Clients Speak
          </TitleText>
          <Text lineHeight={2} center>
            We have been working with clients around the world
          </Text>

          <Card
            style={{ width: "90%" }}
            background={"transparent"}
            top={"2em"}
            bottom={"2em"}
            WidthResponsive
          >
            <Carousel cols={3} rows={1} gap={40} loop autoplay={2000}>
              {testimonials.map((item, pos) => (
                <Carousel.Item
                  data-aos="zoom-in"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="500"
                >
                  <Card radius={0.5}>
                    <div
                      style={{
                        marginTop: "1em",
                        marginBottom: "1em",
                        marginLeft: "1em",
                        marginRight: "1em",
                      }}
                    >
                      <TitleText size={1.5} lineHeight={2}>
                        {item?.title}
                      </TitleText>
                      <Text>{item?.text}</Text>
                    </div>
                  </Card>
                  <Card background={"transparent"} top={"1em"} center>
                    <Circle style={{ marginLeft: "auto", marginRight: "auto" }}>
                      <Avatar
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                      />
                    </Circle>
                    <Text lineHeight={2} color={"rgba(5,5,5,1)"} heavy center>
                      {item?.testifier}
                    </Text>
                    <Text center>{item?.testisfierCompany}</Text>
                  </Card>
                </Carousel.Item>
              ))}
            </Carousel>
          </Card>
        </div>
      </MainSection>
    </>
  );
};

export default AboutUsTestimonials;
