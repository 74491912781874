import { useState } from "react";

import { Helmet } from "react-helmet";

import MenuOutlinedIcon from "@material-ui/icons/Menu";

import {
  HeaderHeader,
  LayoutHeaderDropDown,
  LayoutMobile,
} from "../../styles/home/HeaderStyles";
import { Box, MenuIcon } from "../../styles/util/icons";
import {
  Card,
  Grid,
  LinkText,
  MainSection,
  ScreenControl,
} from "../../styles/util/UtilStyles";
import vikkyLogo from "../../assets/VM-3-TB 1.png";
import { LayoutProps } from "../../types/LayoutTypes";
import { useHistory } from "react-router-dom";

const Header = (props: LayoutProps) => {
  const { pageTitle, color } = props;
  const history = useHistory();

  const [dropDown, setDropDown] = useState(false);
  const [state, setState] = useState(true);
  const [textColor, setTextColor] = useState(color);

  const triggerMenu = () => {
    setDropDown(!dropDown);
  };

  const scrollFunction = () => {
    if (document.documentElement.scrollTop > 88) {
      setState(false);
      setTextColor("rgba(0, 0, 0, 0.5)");
    } else {
      setState(true);
      if (color) {
        setTextColor(color);
      }
    }
  };

  window.onscroll = function () {
    scrollFunction();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      <HeaderHeader state={state}>
        <MainSection>
          <Grid flex spacing={2}>
            <Grid lg={6} md={6} sm={4} xs={4}>
              <Card
                background={"transparent"}
                flex
                top={"1.2em"}
                spaceBetween
                verticalCenter
              >
                <Box
                  onClick={() => history.push("/home")}
                  width={"fit-content"}
                  background={"transparent"}
                >
                  <img src={vikkyLogo} alt="" />
                </Box>
                {/* <HeaderLogo /> */}
              </Card>
            </Grid>
            <Grid lg={6} md={6} sm={8} xs={8}>
              <ScreenControl lg md sm>
                <Card
                  background={"transparent"}
                  flex
                  top={"2em"}
                  spaceBetween
                  verticalCenter
                >
                  <LinkText
                    color={textColor}
                    to="/"
                    size={0.9}
                    nounderline
                    heavy
                  >
                    Home
                  </LinkText>
                  <LinkText
                    color={textColor}
                    to="/our-services/"
                    size={0.9}
                    nounderline
                    heavy
                  >
                    Our Services
                  </LinkText>
                  <LinkText
                    color={textColor}
                    to="/about-us/"
                    size={0.9}
                    nounderline
                    heavy
                  >
                    About Us
                  </LinkText>
                  <LinkText
                    color={textColor}
                    to="/faqs/"
                    size={0.9}
                    nounderline
                    heavy
                  >
                    FAQs
                  </LinkText>
                  <LinkText
                    color={textColor}
                    to="/contact-us/"
                    size={0.9}
                    nounderline
                    heavy
                  >
                    Contact Us
                  </LinkText>
                </Card>
              </ScreenControl>

              <ScreenControl xs>
                <Card
                  background={"transparent"}
                  flex
                  top={"1em"}
                  spaceBetween
                  verticalCenter
                >
                  <MenuIcon onClick={() => triggerMenu()}>
                    <MenuOutlinedIcon />
                  </MenuIcon>
                </Card>
              </ScreenControl>
            </Grid>
          </Grid>
        </MainSection>

        <LayoutHeaderDropDown state={dropDown}>
          <LayoutMobile state={dropDown}>
            <Card top={"2em"} bottom={"0em"} background={"transparent"} center>
              <LinkText to="/" lineHeight={2.5} size={0.9} nounderline center>
                Home
              </LinkText>
              <LinkText
                to="/our-services/"
                lineHeight={2.5}
                size={0.9}
                nounderline
                center
              >
                Our Services
              </LinkText>
              <LinkText
                to="/about-us/"
                lineHeight={2.5}
                size={0.9}
                nounderline
                center
              >
                About Us
              </LinkText>
              <LinkText
                to="/faqs/"
                lineHeight={2.5}
                size={0.9}
                nounderline
                center
              >
                FAQs
              </LinkText>
              <LinkText
                to="/contact-us/"
                lineHeight={2.5}
                size={0.9}
                nounderline
                center
              >
                Contact Us
              </LinkText>
            </Card>
          </LayoutMobile>
        </LayoutHeaderDropDown>
      </HeaderHeader>
    </>
  );
};

export default Header;
