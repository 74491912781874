import { FC } from "react";
import { Main } from "../../styles/util/UtilStyles";
import { LayoutProps } from "../../types/LayoutTypes";
import Footer from "./Footer";
import Header from "./Header";

const Layout: FC<LayoutProps> = (props) => {
  return (
    <>
      <Header {...props} />
      <Main>{props?.children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
