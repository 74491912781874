import styled from "styled-components";

export const HomeImgRight = styled.img`
  width: 80%;
  height: 80%;
  float: right;
  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
    float: left;
    margin-top: 2em;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    float: left;
    margin-top: 2em;
  }
`;

// --- Our services ---
export const ServicesSection = styled.section`
  width: 85%;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
`;

// --- Contact Us ---
export const ContactSection = styled.section`
  width: 100%;
  height: 100%;
  background: #f2911b;
  border-radius: 2em;
  // border-radius: 2em 1020em 2em 2em;
`;
