import Layout from "../../components/layout/Layout";
import {
  AboutProduct,
  OtherServices,
  TopBackgroundView,
} from "../../components/products";

import investImage from "../../assets/products_bg/HEADERS-VimeVest.png";
import firstImage from "../../assets/sliders/SLIDER-VimeVest.png";
import { useEffect } from "react";

const aboutService = `
To become financially independent, people need to have access to good investment opportunities and that’s what we thought of when we created Vikkymore Investment, an investment arm of the company that gives mouth-watering interest rates to our long-term customers. 
<br/>
To learn more about our Investment options, click the button below to chat with a customer service representative.
`;

const VikkyInvestmentPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout pageTitle={"Vikkymore - Vikky Investment"}>
        <TopBackgroundView
          bgImage={`linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,1)), url(${investImage})`}
          productName={"Vikky Investment"}
        />

        <AboutProduct
          firstImg={firstImage}
          aboutService={aboutService}
          url={"https://wa.me/message/"}
          urlType="whatsapp"
        />

        <OtherServices />
      </Layout>
    </>
  );
};

export default VikkyInvestmentPage;
