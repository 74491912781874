import { Grid } from "@material-ui/core";
import FacebookRoundedIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";

import { FooterFooter } from "../../styles/home/FooterStyles";
import {
  Button,
  Card,
  Divider,
  LinkText,
  MainSection,
  Text,
  TextField,
  TitleText,
} from "../../styles/util/UtilStyles";
import vikkyLogo from "../../assets/VM-3-TB 1.png";
import { Box, Circle } from "../../styles/util/icons";
import { Link, useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  return (
    <>
      <FooterFooter>
        <MainSection>
          <Grid container spacing={2}>
            <Grid item lg={3} md={4} sm={6} xs={6}>
              <TitleText bottom={0.5} size={1} color={"#0F2B3D"} heavy>
                Product
              </TitleText>
              <LinkText
                size={0.9}
                lineHeight={2.5}
                to="/products/dd-xpress/"
                nounderline
              >
                DD Xpress Delivery
              </LinkText>
              <LinkText
                size={0.9}
                lineHeight={2.5}
                to="/products/vikky-exchange/"
                nounderline
              >
                Vikkymore Exchange
              </LinkText>
              <LinkText
                to="/products/vimepay/"
                size={0.9}
                lineHeight={2.5}
                nounderline
              >
                Vimepay
              </LinkText>
              <LinkText
                size={0.9}
                lineHeight={2.5}
                to="/products/vikky-investment"
                nounderline
              >
                Vikky Investment
              </LinkText>
              <LinkText
                size={0.9}
                lineHeight={2.5}
                to="/products/vikky-foundation/"
                nounderline
              >
                Vikkymore Foundation
              </LinkText>
              <LinkText
                size={0.9}
                lineHeight={2.5}
                to="/products/agromart/"
                nounderline
              >
                Vikkymore Agromart
              </LinkText>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={6}>
              <TitleText bottom={0.5} size={1} color={"#0F2B3D"} heavy>
                Information
              </TitleText>
              <LinkText size={0.9} lineHeight={2.5} to="/faqs/" nounderline>
                FAQ
              </LinkText>
              <LinkText
                size={0.9}
                lineHeight={2.5}
                to={{ pathname: "https://blog.vimepay.com/" }}
                target="__blank"
                nounderline
              >
                Blog
              </LinkText>
              <LinkText
                size={0.9}
                lineHeight={2.5}
                to="/contact-us/"
                nounderline
              >
                Support
              </LinkText>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={6}>
              <TitleText bottom={0.5} size={1} color={"#0F2B3D"} heavy>
                Company
              </TitleText>
              <LinkText to="/about-us/" size={0.9} lineHeight={2.5} nounderline>
                About us
              </LinkText>
              <LinkText
                to="/contact-us/"
                size={0.9}
                lineHeight={2.5}
                nounderline
              >
                Contact us
              </LinkText>
              <LinkText
                to="/our-services/"
                size={0.9}
                lineHeight={2.5}
                nounderline
              >
                Our Services
              </LinkText>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Card background={"rgba(242, 145, 27, 0.5)"}>
                <Card
                  background={"transparent"}
                  left={"1em"}
                  right={"1em"}
                  top={"1em"}
                  bottom={"1em"}
                >
                  <TitleText bottom={1} size={1} color={"#0F2B3D"} heavy>
                    Subscribe
                  </TitleText>
                  <Grid container spacing={0}>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      <TextField placeholder="Email address" />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Button radius={0} background={"rgba(242, 145, 27, 1)"}>
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                  <Text top={1} size={0.9} lineHeight={1.5}>
                    Subscribe to receive our Newsletters. We have a lot in store
                    for you but you may not get the information until you do.
                  </Text>
                </Card>
              </Card>
            </Grid>
          </Grid>
          <Divider top={2} bottom={2} />

          <Grid container spacing={3}>
            <Grid item lg={4} md={4} xs={6} sm={6}>
              <Box
                top={"1em"}
                width={"100%"}
                height={"3em"}
                background={"transparent"}
                onClick={() => history.push("/home")}
              >
                <img src={vikkyLogo} alt="VikkyMore" />
              </Box>
            </Grid>
            <Grid item lg={5} md={4} xs={6} sm={6}>
              <Text top={2}>
                {new Date().getFullYear()} VikkyMore. All Rights Reserved.
              </Text>
            </Grid>
            <Grid item lg={3} md={4} xs={8} sm={6}>
              <Card top={"1em"} flex center>
                {/* spaceBetween */}
                <Link
                  to={{ pathname: "" }}
                  style={{ display: "none" }}
                  target="_blank"
                >
                  <Circle width={"2.5em"} height={"2.5em"} cursor={true}>
                    <FacebookRoundedIcon
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    />
                  </Circle>
                </Link>
                <Link
                  style={{ marginRight: "1em" }}
                  to={{
                    pathname:
                      "https://www.linkedin.com/company/vikkymore-multi-business-ltd",
                  }}
                  target="_blank"
                >
                  <Circle width={"2.5em"} height={"2.5em"} cursor={true}>
                    <LinkedInIcon
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    />
                  </Circle>
                </Link>
                <Link
                  style={{ marginLeft: "1em" }}
                  to={{
                    pathname:
                      "https://instagram.com/vikkymorembl?utm_medium=copy_link",
                  }}
                  target="_blank"
                >
                  <Circle width={"2.5em"} height={"2.5em"} cursor={true}>
                    <InstagramIcon
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    />
                  </Circle>
                </Link>
                <Link
                  to={{ pathname: "" }}
                  style={{ display: "none" }}
                  target="_blank"
                >
                  <Circle width={"2.5em"} height={"2.5em"} cursor={true}>
                    <TwitterIcon
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    />
                  </Circle>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </MainSection>
      </FooterFooter>
    </>
  );
};

export default Footer;
