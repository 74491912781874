import Layout from "../../components/layout/Layout";
import {
  AboutProduct,
  OtherServices,
  TopBackgroundView,
} from "../../components/products";

import agromartImage from "../../assets/products_bg/HEADERS-Agromart.png";
import firstImage from "../../assets/sliders/SLIDER-Vikkymore-Agromart.png";
import { useEffect } from "react";

const aboutService = `
Vikkymore Agromart is the agricultural arm of Vikkymore Multi-Business Limited located in Ibadan, Nigeria where we produce both cash and food crops to support the livelihood of the people and also help in the economic growth of the country. 
<p>
According to Statista, agriculture accounts for about 24% of Nigeria’s GDP. Vikkymore, because of her interest in becoming a change agent and a huge contributor to the economy of Nigeria has jumped on the agricultural opportunity by building an AgroMart where agricultural produce would be made available to Nigerian consumers. 
</P>
Click the button below to learn more about our Agromart.
`;

const VikkyAgromartPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout pageTitle={"Vikkymore - Vikky AgroMart"}>
        <TopBackgroundView
          bgImage={`linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,1)), url(${agromartImage})`}
          productName={"Vikky Agromart"}
        />

        <AboutProduct
          firstImg={firstImage}
          aboutService={aboutService}
          url={"https://wa.me/message/"}
          urlType="whatsapp"
        />

        <OtherServices />
      </Layout>
    </>
  );
};

export default VikkyAgromartPage;
