import { useEffect, useState } from "react";

import { Grid } from "@material-ui/core";

import {
  Button,
  Card,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

// import ddImage1 from "../../assets/Ellipse 50.png";
import ddImage2 from "../../assets/Rectangle 385.png";
import { IAboutProductProps } from "../../types/ProductsTypes";

const AboutProduct = (props: IAboutProductProps) => {
  const { url, aboutService, urlType, firstImg } = props;

  const [linkName, setLinkName] = useState("");

  useEffect(() => {
    if (urlType === "whatsapp") {
      setLinkName("Chat with Us.");
    } else if (urlType === "website") {
      setLinkName("Visit website");
    } else {
      setLinkName("View Gallery");
    }
  }, [urlType]);

  return (
    <>
      <MainSection
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
      >
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"5em"}>
              <TitleText size={1.5} lineHeight={2}>
                About this service
              </TitleText>
              <Text
                top={1}
                lineHeight={2}
                dangerouslySetInnerHTML={{
                  __html: `${aboutService}`,
                }}
              />
            </Card>

            <Card top={"2em"} width={20} flex spaceBetween>
              <LinkText to={{ pathname: url }} target="_blank" nounderline>
                <Button background={"rgba(242, 145, 27, 1)"} width={11}>
                  {linkName}
                </Button>
              </LinkText>
              <LinkText to="/" nounderline>
                <Button
                  color={"rgba(5,5,5,0.7)"}
                  border={"1px solid rgba(242, 145, 27, 0.3)"}
                  background={"white"}
                  width={11}
                >
                  Back to homepage
                </Button>
              </LinkText>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"5em"} flex>
              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Card
                    data-aos="zoom-in"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                  >
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={firstImg}
                      alt=""
                    />
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  style={{ display: "none" }}
                >
                  <Card
                    data-aos="zoom-in"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="500"
                    left={"-2em"}
                  >
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={ddImage2}
                      alt=""
                    />
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default AboutProduct;
