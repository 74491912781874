// import BackgroundSlideshow from "react-background-slideshow";
// import BackgroundSlider from 'react-background-slider';

import {
  BackgroundSlider,
  //  MainSection
} from "../../styles/util/UtilStyles";

// --- Background Slider Images here ---
import slider1 from "../../assets/products_slides/slide-Vimepay.png";

// import slider1 from "../../assets/sliders/SLIDER-VimeVest.png";
// import testImage from "../../assets/aboutus/Our-Services.png";

import slider2 from "../../assets/products_slides/slide-DD-Xpress.png";
import slider3 from "../../assets/products_slides/slide-Agromart.png";
import slider4 from "../../assets/products_slides/slide-Exchange.png";
import slider5 from "../../assets/products_slides/slide-Vikkymore-Foundation.png";
import slider6 from "../../assets/sliders/SLIDER-VimeVest.png";

import mobileSlider1 from "../../assets/products_slides/mobile/Mobile-Vimepay.png";

import mobileSlider2 from "../../assets/products_slides/mobile/Mobile-DD-Xpress.png";
import mobileSlider3 from "../../assets/products_slides/mobile/Mobile-Agromart.png";
import mobileSlider4 from "../../assets/products_slides/mobile/Mobile-Exchange.png";
import mobileSlider5 from "../../assets/products_slides/mobile/Mobile-Foundation.png";
import mobileSlider6 from "../../assets/products_slides/mobile/Mobile-VimeVest.png";

const HomeBgLayer = () => {

  return (
    <>

      <BackgroundSlider
        images={[slider1, slider2, slider3, slider4, slider5, slider6]}
        mobileImages={[
          mobileSlider1,
          mobileSlider2,
          mobileSlider3,
          mobileSlider4,
          mobileSlider5,
          mobileSlider6
        ]}
      />
      {/* <MainSection
        top={8}
        bottom={50}
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
      ></MainSection> */}
    </>
  );
};

export default HomeBgLayer;
