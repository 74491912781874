import { Grid } from "@material-ui/core";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";

import { Circle } from "../../styles/util/icons";
import { Button, Card, Text, TextField } from "../../styles/util/UtilStyles";

const SubscriberBar = () => {
  return (
    <>
      <Card
        // height={10}
        top={"5em"}
        bottom={"10em"}
        shadow
        WidthResponsive
        lgWidth={"90%"}
        mdWidth={"90%"}
        smWidth={"90%"}
        xsWidth={"90%"}
        radius={0.2}
        background={"rgba(242, 145, 27, 0.3)"}
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
      >
        <div
          style={{
            marginTop: "2em",
            marginLeft: "1em",
            marginRight: "1em",
            marginBottom: "2em",
          }}
        >
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={5} xs={12}>
              <Card flex verticalCenter background={"transparent"}>
                <Circle cursor={true}>
                  <EmailRoundedIcon
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  />
                </Circle>
                <Text color={"0F2B3D"} left={"1em"} lineHeight={1.5} heavy>
                  Join the newsletter <br />
                  and read the new post first
                </Text>
              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={7} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={8} md={6} sm={8} xs={8}>
                  <TextField placeholder="Email*" />
                </Grid>
                <Grid item lg={4} md={6} sm={4} xs={4}>
                  <Button background={"#F2911B"}>Subscribe</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default SubscriberBar;
