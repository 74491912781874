import { useEffect } from "react";
import {
  // AboutUsBottomView,
  AboutUsMiddleView,
  AboutUsServices,
  AboutUsTestimonials,
  AboutUsTopView,
} from "../../components/aboutus";
import Layout from "../../components/layout/Layout";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout pageTitle={"Contact Us"}>
        <AboutUsTopView />

        <AboutUsMiddleView />

        <AboutUsServices />

        <AboutUsTestimonials />

        {/* <AboutUsBottomView /> */}
      </Layout>
    </>
  );
};

export default AboutUsPage;
