import Layout from "../../components/layout/Layout";
import {
  AboutProduct,
  OtherServices,
  TopBackgroundView,
} from "../../components/products";

import foundationImage from "../../assets/products_bg/HEADERS-Foundation.png";
import firstImage from "../../assets/sliders/SLIDER-Vikkymore-Foundation.png";
import { useEffect } from "react";

const aboutService = `
One of our goals as an organization is to be change agents in the community where we operate and we do this by participating in Corporate Social Responsibilities. Our foundation is in charge of reaching out to the less privileged and supporting them in different ways that we can. We have visited a number of Orphanages since inception and have supported many through our foundation. 
<br/>
<br/>
Olaoye Victor, the CEO and creator of Vikkymore Multi-Business Limited is the brain behind the Vikkymore Foundation. Vikkymore Foundation was established on January 23rd, 2021, to help the less privileged in and outside the city where the company is located. This movement was inspired by his love for underprivileged and disadvantaged youngsters.
<br/>
The members of this foundation are employees of Vikkymore Multi-Business Limited. Since inception, we have been changing lives, one person at a time, the little way that we can. The Vikkymore Foundation team has visited a large number of orphanages since it was founded with He Careth Orphanage in Akobo, Ibadan being the first. We visit various homes each month. 
<br/>
Click the Gallery button below or this <a href="https://instagram.com/dd_xpress_delivery?utm_medium=copy_link" target="__blank">Instagram link</a> below to let our pictures speak. 

`;

const VikkyFoundationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout pageTitle={"Vikkymore - Vikky Foundation"}>
        <TopBackgroundView
          bgImage={`linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,1)), url(${foundationImage})`}
          productName={"Vikky Foundation"}
        />

        <AboutProduct
          aboutService={aboutService}
          firstImg={firstImage}
          url={
            "https://drive.google.com/drive/u/0/folders/12uSIuOsM7PxBIAa4fq6uVOWeMXcI5Ylq"
          }
          urlType="gallery"
        />

        <OtherServices />
      </Layout>
    </>
  );
};

export default VikkyFoundationPage;
