import { useEffect, useState } from "react";

import { FaqsQuestions } from "../../components/faqs";
import { HomeAbout, HomeBgLayer, HomeContactUs } from "../../components/home";
import Layout from "../../components/layout/Layout";
import ServicesListCard from "../../components/services/ServicesListCard";
import { allFaqQuests } from "../../localDb/FaqsDb";
import {
  Button,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";
import { FaqEachQuestTypes } from "../../types/FaqTypes";

const HomePage = () => {
  const [faqs, setFaqs] = useState<FaqEachQuestTypes[]>(allFaqQuests);

  useEffect(() => {
    let fetchedData = Array<FaqEachQuestTypes>();
    allFaqQuests.map((items) => {
      if (items.category === "VIKKYMORE") {
        fetchedData.push(items);
      }
      return 0;
    });
    setFaqs(fetchedData);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout pageTitle={"Vikkymore MBL - YOU CAN DO MORE"} color={"white"}>
        <HomeBgLayer />

        <TitleText top={4} heavy center>
          OUR SERVICES
        </TitleText>
        <Text lineHeight={2} top={1} left={"2em"} right={"2em"} center>
          Pick from the variety of services we offer, trust us to deliver 100%!
        </Text>

        <ServicesListCard />

        <TitleText top={5} bottom={2} heavy center>
          ABOUT US
        </TitleText>

        <HomeAbout />

        <TitleText top={2} bottom={1} heavy center>
          FREQUENTLY ASKED QUESTIONS
        </TitleText>
        <MainSection>
          <FaqsQuestions faqs={faqs} />

          <LinkText to="/faqs/" nounderline center>

            <Button background="rgba(242, 145, 27, 0.7)" width={10} center>
              View All
            </Button>
          </LinkText>
        </MainSection>

        <TitleText top={2} bottom={1} heavy center>
          CONTACT US
        </TitleText>
        <div>
          <MainSection>
            <HomeContactUs />
          </MainSection>
        </div>
      </Layout>
    </>
  );
};

export default HomePage;
