import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import { PageTransition } from "./AppStyles";
import { ContactUsPage } from "./pages/contactus";
import { FaqsPage } from "./pages/faqs";
import { HomePage } from "./pages/Home";
import {
  DDXpressPage,
  VikkyAgromartPage,
  VikkyExchangePage,
  VikkyFoundationPage,
  VikkyInvestmentPage,
  VimepayPage,
} from "./pages/products";
import { AboutUsPage } from "./pages/aboutus";
import { OurServicesPage } from "./pages/ourServices";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <PageTransition>
      <Router>
        <Switch>
          <Route exact path="/faqs/" component={FaqsPage} />
          <Route exact path="/contact-us/" component={ContactUsPage} />

          {/* --- Products ---- */}
          <Route
            exact
            path="/products/agromart/"
            component={VikkyAgromartPage}
          />
          <Route
            exact
            path="/products/vikky-investment/"
            component={VikkyInvestmentPage}
          />
          <Route
            exact
            path="/products/vikky-exchange/"
            component={VikkyExchangePage}
          />
          <Route
            exact
            path="/products/vikky-foundation/"
            component={VikkyFoundationPage}
          />
          <Route exact path="/products/vimepay" component={VimepayPage} />
          <Route exact path="/products/dd-xpress/" component={DDXpressPage} />

          {/* --- About Us Page ---- */}
          <Route exact path="/about-us/" component={AboutUsPage} />

          {/* --- Our Services Page ---- */}
          <Route exact path="/our-services/" component={OurServicesPage} />

          <Route exact path="/home/" component={HomePage} />

          <Route exact path="*" component={HomePage}>
            <Redirect to="/home" />
          </Route>
        </Switch>
      </Router>
    </PageTransition>
  );
}

export default App;
