import styled from "styled-components";

export const FaqsSection = styled.section`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  @media screen and (max-width: 992px) {
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

export const FaqsEachSegment = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  min-height: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
`;
