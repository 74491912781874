import React from "react";
import { ContactBox, SubscriberBar } from "../../components/contactus";
import Layout from "../../components/layout/Layout";
import {
  Text,
  TitleText,
  YellowBackground,
} from "../../styles/util/UtilStyles";

const ContactUsPage = () => {
  return (
    <>
      <Layout pageTitle={"Contact Us"}>
        <YellowBackground>
          <TitleText top={8} heavy center>
            Contact Us
          </TitleText>
          <Text lineHeight={2} top={1} left={"2em"} right={"2em"} center>
            Do you have questions about our services?
            <br />
            Vikkymore multi-business limited is always available to take your complaints and enquiries.

          </Text>
          <ContactBox />
        </YellowBackground>

        <SubscriberBar />
      </Layout>
    </>
  );
};

export default ContactUsPage;
