import { Grid } from "@material-ui/core";
import { HomeImgRight } from "../../styles/home/HomeStyles";
import {
  Button,
  MainSection,
  ScreenControl,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import imgRight from "../../assets/aboutus/Our-Services-Top-right.png";
import { useHistory } from "react-router-dom";

const ServicesBgLayer = () => {
  const history = useHistory();
  return (
    <MainSection
      top={8}
      data-aos="zoom-in"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
    >
      <Grid container spacing={0}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <TitleText
            top={1.5}
            lineHeight={1.5}
            size={2.2}
            color={"#0F2B3D"}
            heavy
          >
            Investments? Exchange? Delivery Services? We are here for you!
          </TitleText>
          <Text top={1.5} lineHeight={2}>
            Pick from any of our six services.
          </Text>
          <Grid container spacing={4}>
            <Grid item lg={4} md={6} sm={4} xs={6}>
              <Button
                onClick={() => history.push("/our-services/")}
                top={3}
                height={3}
                background={"#F2911B"}
              >
                Our Services
              </Button>
            </Grid>
            <Grid item lg={4} md={6} sm={4} xs={6}>
              <Button
                onClick={() => history.push("/contact-us/")}
                top={3}
                height={3}
                color={"#333333"}
              >
                Contact Us
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ScreenControl lg xs md sm>
            <HomeImgRight src={imgRight} alt="" />
          </ScreenControl>
        </Grid>
      </Grid>
    </MainSection>
  );
};

export default ServicesBgLayer;
