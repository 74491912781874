import Layout from "../../components/layout/Layout";
import {
  AboutProduct,
  OtherServices,
  TopBackgroundView,
} from "../../components/products";

import vimepayImage from "../../assets/products_bg/HEADERS-Vimepay.png";
import firstImage from "../../assets/sliders/SLIDER-Vimepay.png";
import { useEffect } from "react";

const aboutService = `
In a bid to make Nigeria and Africa meet up with the digital advancement in the first world countries, Vikkymore came up with a solution and that solution is Vimepay. Vimepay is a subsidiary of Vikkymore Multi-business Ltd, with its headquarters in Ibadan, Nigeria. With the support of one of the best teams, Vimepay is gradually reaching its goal of becoming one of the best platforms where anybody, no matter where you are, can trade Bitcoin with ease.
<br/>
Vimepay is an online financial escrow based platform that allows you to safely buy, sell and store cryptocurrencies anywhere you are.
<br/>
<br/>
Vimepay is the only crypto bank in Africa with an online financial escrow-based platform that allows you to buy, sell and store cryptocurrencies anywhere you are safely. Vimepay was developed by us and for us, because we understand the system better.
<br/>
Click the button below to visit our website and start trading or <a href="" target="__blank">view our Instagram page here</a>.
`;

const VimepayPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout pageTitle={"Vikkymore - VimePay"}>

        <TopBackgroundView
          bgImage={`linear-gradient(0deg, rgba(0,0,0,0.5), rgba(255,255,255,1)), url(${vimepayImage})`}
          productName={"Vimepay"}
        />

        <AboutProduct
          firstImg={firstImage}
          aboutService={aboutService}
          url={"https://vimepay.com"}
          urlType="website"
        />

        <OtherServices />
      </Layout>
    </>
  );
};

export default VimepayPage;
