import { Grid } from "@material-ui/core";
import {
  Button,
  Card,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

const HomeAbout = () => {
  return (
    <>
      <MainSection
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
      >
        <Grid container spacing={4}>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
          >
            <TitleText size={1.5}>The Mission</TitleText>
            <Text top={1} size={0.9} color={"#0F2B3D"} lineHeight={2}>
              <p>
                Vikkymore Multi-business Limited seeks to create a better life
                for Nigerians by providing affordable services to the people and
                enlightening them about the financial opportunities available to
                them. At Vikkymore MBL, we believe in providing MORE and
                exceeding our customer’s expectations.
              </p>

              <p>Our objectives include:</p>
              <li>
                To exceed our customers’ expectations and bring the best value
                to consumers at a low cost.{" "}
              </li>
              <li>To provide job opportunities to Nigerians. </li>
              <li>
                To become a leading crypto trading company and the first crypto
                bank in Nigeria.
              </li>
              <li>To become a leading distributor of farm produce.</li>
              <li>
                To promote convenience for our clients and establish customer
                satisfaction through our brands.
              </li>
              <li>
                To provide efficient services in financial management,
                investment and advisory.
              </li>
              <li>
                To be socially responsible in the communities where we operate.
              </li>
              <li>
                To promote and establish subsidiary companies for business
                expansion.
              </li>
              <li>To become change agents in our country, Nigeria.</li>
            </Text>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
          >
            <TitleText size={1.5}>The Vision</TitleText>
            <Text top={1} size={0.9} color={"#0F2B3D"} lineHeight={2}>
              <i>
                “Financial freedom is available to those who learn about it and
                work for it.” ― Robert Kiyosaki
              </i>
              <p>
                Our vision is to lead Nigerians to financial freedom, become
                pioneers of the first Nigerian Crypto Bank, and be the go-to
                company for affordable services.
              </p>
            </Text>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Text
              top={1}
              left={"15%"}
              right={"15%"}
              size={0.9}
              color={"#0F2B3D"}
              lineHeight={2}
              center
            >
              <b style={{ color: "#F2911B" }}>Vikkymore</b> was established with
              the aim of providing the people with MORE than they have: by
              introducing digital assets to the people and an opportunity to
              invest, and from there the agricultural, logistics, trading, and
              exchange branches of the company were created.
            </Text>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Card textAlign>
              <Button background={"#F2911B"} width={10} center>
                Let's go
              </Button>
            </Card>
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default HomeAbout;
