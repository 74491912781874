import { Grid } from "@material-ui/core";
import {
  Card,
  MainSection,
  Text,
  TitleText,
  YellowBackground,
} from "../../styles/util/UtilStyles";

const resultsCount = [
  { no: 6, text: "Unique Brands" },
  { no: 3, text: "Years of Service" },
  { no: "1,000+", text: "Satisfied Customers" },
  { no: 3, text: "Countries" },
];

const AboutUsTopView = () => {
  return (
    <>
      <YellowBackground>
        <MainSection>
          <TitleText top={8} heavy center>
            We're a results driven Team
          </TitleText>
          <Text lineHeight={2} top={1} left={"2em"} right={"2em"} center>
            Vikkymore is a company with 6 unique brands that were founded to be
            the solution to the economic problems Nigerians are facing. The
            company was established in 2018 when the founder, Victor Olaoye
            relocated to Ibadan, the largest city by geographical area and
            third-largest city by population, and noticed that the people
            staying in Ibadan had little or no access to affordable services and
            opportunities for financial growth.
          </Text>

          <Card top={"4em"} bottom={"4em"} background={"transparent"}>
            <Grid container spacing={4}>
              {resultsCount.map((item, pos) => (
                <Grid
                  data-aos="zoom-in"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="500"
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={6}
                  key={pos}
                >
                  <Card background={"transparent"}>
                    <TitleText center heavy>
                      {item?.no}
                    </TitleText>
                    <Text top={1} center>
                      {item?.text}
                    </Text>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </MainSection>
      </YellowBackground>
    </>
  );
};

export default AboutUsTopView;
