import { Card, LinkText, Text, TitleText } from "../../styles/util/UtilStyles";

import dummyIcon from "../../assets/DD-Xpress-Delivery 1@2x.png";
import { Box } from "../../styles/util/icons";

import { ServicesTypes } from "../../types/ServicesTypes";

const ServiceCard = (props: ServicesTypes) => {
  const { details, pos } = props;

  return (
    <>
      <Card
        height={20}
        shadowAlt={"0px 8px 15px rgba(0, 0, 0, 0.1)"}
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        background={pos % 2 === 0 ? "#f2911b" : "white"}
      >
        <Box
          background={pos % 2 === 0 ? "#f2911b" : "white"}
          width={"5em"}
          height={"5em"}
          top={"1em"}
          center
        >
          <img
            src={dummyIcon}
            alt=""
            style={{ width: "100%", height: "100%", display: 'none' }}
          />
        </Box>

        <TitleText top={0} color={pos % 2 !== 0 ? "#0F2B3D" : "white"} size={1.2} heavy center>
          {details?.name}
        </TitleText>
        <Text top={1} color={pos % 2 !== 0 ? "rgba(0, 0, 0, 0.5)" : "white"} center>
          {details?.preText}
        </Text>

        <Text
          left={"1em"}
          right={"1em"}
          top={2}
          lineHeight={2}
          bottom={1}
          center
        >
          {details?.text}
        </Text>

        <Text center>
          <LinkText to={details?.link} color={pos % 2 !== 0 ? "#f2911b" : "white"} nounderline center heavy>
            View service
          </LinkText>
        </Text>
      </Card>
    </>
  );
};

export default ServiceCard;
