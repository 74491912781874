import { Text, TitleText } from "../../styles/util/UtilStyles";

const FaqTitleText = () => {
  return (
    <>
      <TitleText top={1} heavy center>
        Choose which Product
      </TitleText>

      <Text lineHeight={2} top={1} left={"2em"} right={"2em"} center>
        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br /> Blandit
        habitant ipsum tincidunt enim ac ut consectetur. Nulla dolor amet nisi
        cras amet, adipiscing. . */}
      </Text>
    </>
  );
};

export default FaqTitleText;
