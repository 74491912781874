import { useEffect, useState } from "react";

import {
  Link
} from "react-scroll";

import {
  Button,
  Card,
  SearchField,
  Text,
  TextField,
  TitleText,
  YellowBackground,
} from "../../styles/util/UtilStyles";
import { Grid } from "@material-ui/core";
import { FaqsQuestions, FaqTitleText } from "../../components/faqs";
import Layout from "../../components/layout/Layout";
import { FaqsSection } from "../../styles/faqs/FaqsStyles";
import { allFaqQuests } from "../../localDb/FaqsDb";
import { FaqEachQuestTypes } from "../../types/FaqTypes";

const productList = [
  "VIMEPAY",
  "VIKKYMORE FOUNDATION",
  "DDXPRESS",
  "VIKKYMORE AGROMART",
  "VIKKYMORE INVESTMENT",
  "VIKKYMORE EXCHANGE",
];

const FaqsPage = () => {
  const [selFaq, setSelFaq] = useState("VIMEPAY");

  const [faqs, setFaqs] = useState<FaqEachQuestTypes[]>(allFaqQuests);
  const [searchText, setSearchText] = useState("");

  const handleSelFaq = (category: string) => {
    setSelFaq(category);
    let fetchedData = Array<FaqEachQuestTypes>();
    allFaqQuests.map((items) => {
      if (items.category === category) {
        fetchedData.push(items);
      }
      return 0;
    });
    setFaqs(fetchedData);
  };

  const handleSearch = () => {
    let search = searchText;
    let fetchedData = Array<FaqEachQuestTypes>();
    allFaqQuests.map((items) => {
      if (
        items.question.includes(search) ||
        items.answer.includes(search) ||
        items.category.includes(search)
      ) {
        fetchedData.push(items);
      }
      return 0;
    });
    setFaqs(fetchedData);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout pageTitle={"FAQs"}>
        <YellowBackground>
          <TitleText top={8} heavy center>
            Hello, how can we help you?
          </TitleText>

          <SearchField>
            <Grid container spacing={2}>
              <Grid item lg={8} xs={8} md={8} sm={8}>
                <TextField
                  onChange={(e) => setSearchText(e.target.value)}
                  noBorder
                  height={2}
                  placeholder="Ask a question"
                />
              </Grid>
              <Grid item lg={4} xs={4} md={4} sm={4}>
                <Link
                  activeClass="active"
                  to="results"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                >
                  <Button
                    onClick={handleSearch}
                    height={3}
                    background={"rgba(242, 145, 27, 1)"}
                  >
                    Search
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </SearchField>

          <Text
            lineHeight={2}
            top={2}
            bottom={2}
            left={"2em"}
            right={"2em"}
            center
          >
            or choose a category to quickly find the help you need
          </Text>
        </YellowBackground>

        <FaqTitleText />

        <FaqsSection>
          <Card>
            <Grid container spacing={4}>
              {productList.map((item, pos) => (
                <Grid item lg={2} md={4} sm={4} xs={6} key={pos}>
                  <Card
                    height={8}
                    shadowAlt={"0px 8px 15px rgba(0, 0, 0, 0.1)"}
                    background={item === selFaq ? "#f2911b" : "white"}
                    radius={0.5}
                    style={{ border: "0.1px solid #f2911b", cursor: "pointer" }}
                    onClick={() => handleSelFaq(item)}
                  >
                    <Text
                      color={item === selFaq ? "white" : "#f2911b"}
                      top={3.5}
                      size={0.85}
                      lineHeight={1.8}
                      center
                      heavy
                    >
                      {item}
                    </Text>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </FaqsSection>

        <div id="results">
          <FaqsQuestions faqs={faqs} />
        </div>
      </Layout>
    </>
  );
};

export default FaqsPage;
