import styled from "styled-components";

import mailIcon from "./Icons/MailIcon.svg";
import addIcon from "./Icons/AdditionIcon.svg";

// --- Icons here ---
export const MenuIcon = styled.div`
  width: 2em;
  height: 2em;
  background: #f2911b;
  border-radius: 0.5em;
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: white;
  z-index: 10000000000000000000;
`;

export const Circle = styled("div")<{
  height?: string;
  color?: string;
  background?: string;
  width?: string;
  cursor?: boolean;
}>`
  height: ${(props) => (props.height ? `${props.height}` : "3em")};
  width: ${(props) => (props.height ? `${props.height}` : "3em")};
  background: ${(props) =>
    props.background ? `${props.background}` : "#f2911b"};
  color: ${(props) => (props.color ? `${props.color}` : "white")};
  border-radius: 50%;
  background: rgba(242, 145, 27, 0.7);
  display: flex;
  align-items: center;
  align-self: center;
  cursor: ${(props) => (props.cursor ? "pointer" : "none")};
`;

export const Box = styled("div")<{
  height?: string;
  color?: string;
  background?: string;
  width?: string;
  center?: boolean;
  top?: string;
  bottom?: string;
}>`
  height: ${(props) => (props.height ? `${props.height}` : "3em")};
  width: ${(props) => (props.height ? `${props.height}` : "3em")};
  background: ${(props) =>
    props.background ? `${props.background}` : "#f2911b"};
  color: ${(props) => (props.color ? `${props.color}` : "white")};
  margin-left: ${(props) => props.center && "auto"};
  margin-right: ${(props) => props.center && "auto"};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0em")};
  display: flex;
  align-items: center;
  align-self: center;
`;

// export const MailIcon = styled("img")<{
//   height?: string;
//   color?: string;
//   background?: string;
//   width?: string;
// }>`
//   height: ${(props) => (props.height ? `${props.height}` : "1em")};
//   width: ${(props) => (props.height ? `${props.height}` : "1em")};
//   background: ${(props) =>
//     props.background ? `${props.background}` : "#f2911b"};
//   color: ${(props) => (props.color ? `${props.color}` : "white")};
//   border-radius: 50%;
//   background: white;
//   color: red;
//   // background: rgba(242, 145, 27, 0.3);
//   display: flex;
//   align-items: center;
//   align-self: center;
//   background: url(${mailIcon});
//   margin-left: auto;
//   margin-right: auto;
// `;

export const MailIcon = styled("img")<{
  height?: string;
  width?: string;
  center?: boolean;
}>`
  height: ${(props) => (props.height ? `${props.height}` : "1.5em")};
  width: ${(props) => (props.height ? `${props.height}` : "1.5em")};
  color: red;
  border: none;
  background: url(${mailIcon});
  margin-left: ${(props) => props.center && "auto"};
  margin-right: ${(props) => props.center && "auto"};
`;

export const AddIcon = styled("img")<{
  height?: string;
  width?: string;
  center?: boolean;
  cursor?: boolean;
}>`
  height: ${(props) => (props.height ? `${props.height}` : "1.5em")};
  width: ${(props) => (props.height ? `${props.height}` : "1.5em")};
  color: red;
  border: none;
  background: url(${addIcon});
  margin-left: ${(props) => props.center && "auto"};
  margin-right: ${(props) => props.center && "auto"};
  cursor: ${(props) => props.cursor && "pointer"};
  border-radius: 50%;
`;
