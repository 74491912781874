import { MainSection, TitleText } from "../../styles/util/UtilStyles";

import ServicesListCard from "../services/ServicesListCard";

const OtherSevices = () => {
  return (
    <>
      <MainSection>
        <TitleText bottom={2} top={4} heavy center>
          OTHER SERVICES WE OFFER
        </TitleText>

        <ServicesListCard />
      </MainSection>
    </>
  );
};

export default OtherSevices;
