import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styled, { keyframes } from "styled-components";

export const gridCut = 100 / 12;

export const Grid = styled("div")<{
  flex?: boolean;
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
  spacing?: number;
  grid?: boolean;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  cardWidth?: string;
}>`
  display: ${(props) => (props.flex ? "flex" : "block")};
  display: ${(props) => props.grid && "inline-grid"};
  grid-template-columns: ${(props) =>
    props.grid ? "repeat(auto-fit, minmax(300px, 1fr))" : "none"};
  width: ${(props) => (props.lg ? `${props.lg * gridCut}%` : "100%")};
  width: ${(props) => props.cardWidth && `${props.cardWidth}`};
  column-gap: ${(props) => (props.spacing ? `${props.spacing}em` : "0")};
  justify-content: ${(props) => (props.spacing ? "space-between" : "none")};

  margin-top: ${(props) => (props.top ? `${props.top}` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0em")};
  margin-left: ${(props) => (props.left ? "auto" : "0em")};
  margin-right: ${(props) => (props.right ? "auto" : "0em")};

  @media screen and (min-width: 992px) {
    width: ${(props) => (props.md ? `${props.md * gridCut}%` : "100%")};
  }
  @media screen and (min-width: 768px) {
    width: ${(props) => (props.sm ? `${props.sm * gridCut}%` : "100%")};
  }
  @media screen and (max-width: 767px) {
    width: ${(props) => (props.xs ? `${props.xs * gridCut}%` : "100%")};
    flex-direction: ${(props) =>
      props.xs ? props?.xs > 12 && "column" : "row"};
  }
`;

// --- Button ---
export const Button = styled("button")<{
  width?: number;
  height?: number;
  color?: string;
  background?: string;
  top?: number;
  bottom?: number;
  center?: boolean;
  radius?: number;
  border?: string;
}>`
  width: ${(props) => (props.width ? `${props.width}em` : "100%")};
  height: ${(props) => (props.height ? `${props.height}em` : "3.5em")};
  color: ${(props) => (props.color ? `${props.color}` : "white")};
  background: ${(props) =>
    props.background ? `${props.background}` : "F2911B"};
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  margin-left: ${(props) => (props.center ? "auto" : "0em")};
  margin-right: ${(props) => (props.center ? "auto" : "0em")};
  border-radius: ${(props) => (props.radius ? `${props.radius}em` : "0.5em")};
  box-sizing: border-box;
  border: none;
  display: block;
  border: ${(props) => (props.border ? `${props.border}` : "none")};
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: 0.5s opacity;
  &:hover {
    opacity: 0.5;
  }
`;

export const Card = styled("div")<{
  width?: number;
  height?: number;
  background?: string;
  shadow?: boolean;
  center?: boolean;
  flex?: boolean;
  top?: string;
  bottom?: string;
  radius?: number;
  shadowAlt?: string;
  bgImg?: string;
  padding?: string;
  verticalCenter?: boolean;
  spaceBetween?: boolean;
  animateZoom?: boolean;
  left?: string;
  right?: string;
  WidthResponsive?: boolean;
  lgWidth?: string;
  mdWidth?: string;
  smWidth?: string;
  xsWidth?: string;
  textAlign?: boolean;
}>`
  width: ${(props) => (props.width ? `${props.width}em` : "initial")};
  height: ${(props) => (props.height ? `${props.height}em` : "fit-content")};
  background-color: ${(props) =>
    props.background ? `${props.background}` : "white"};
  box-shadow: ${(props) =>
    props.shadow ? `0px 8px 15px rgba(0, 0, 0, 0.1)` : "none"};
  box-shadow: ${(props) => props.shadowAlt && `${props.shadowAlt}`};
  display: ${(props) => (props.flex ? "flex" : "block")};
  justify-content: ${(props) => (props.center ? "center" : "none")};
  text-align: ${(props) => (props.center ? "center" : "none")};
  margin-top: ${(props) => (props.top ? `${props.top}` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}` : "0em")};
  border-radius: ${(props) => (props.radius ? `${props.radius}em` : "0em")};
  background-image: ${(props) => (props.bgImg ? `${props.bgImg}` : "none")};
  padding: ${(props) => (props.padding ? `${props.padding}` : "0.1px")};
  text-align: ${(props) => props.textAlign && "center"};
  align-items: ${(props) => (props.verticalCenter ? "center" : "none")};
  justify-content: ${(props) =>
    props.spaceBetween ? "space-between" : "none"};

  margin-left: ${(props) => (props.left ? `${props.left}` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0em")};
  transition: 0.5s zoom 0.5s background;
  &:hover {
    zoom: ${(props) => props.animateZoom && 1.1};
    // background: #ffe4c5;
  }

  // --- Responsive Parts ----
  margin-left: ${(props) => props.WidthResponsive && "auto"};
  margin-right: ${(props) => props.WidthResponsive && "auto"};
  width: ${(props) => props.lgWidth && `${props.lgWidth}`};
  @media screen and (max-width: 992px) {
    width: ${(props) => props.mdWidth && `${props.mdWidth}`};
  }
  @media screen and (max-width: 768px) {
    width: ${(props) => props.smWidth && `${props.smWidth}`};
  }
  @media screen and (max-width: 481px) {
    width: ${(props) => props.xsWidth && `${props.xsWidth}`};
  }
`;

export const TitleText = styled("h3")<{
  color?: string;
  size?: number;
  position?: string;
  heavy?: boolean;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  lineHeight?: number;
  center?: boolean;
}>`
  color: ${(props) => (props.color ? `${props.color}` : "#0F2B3D")};
  font-size: ${(props) => (props.size ? `${props.size}em` : "2em")};
  text-align: ${(props) => (props.position ? `${props.position}` : "left")};
  font-weight: ${(props) => (props.heavy ? `bold` : "normal")};
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  margin-left: ${(props) => (props.left ? `${props.left}em` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}em` : "0em")};
  line-height: ${(props) => props.lineHeight && `${props.lineHeight}em`};

  // --- For centralization ---
  text-align: ${(props) => props.center && "center"};
  font-family: Roboto;
  font-style: normal;
`;

export const Text = styled("p")<{
  color?: string;
  size?: number;
  position?: string;
  heavy?: boolean;
  top?: number;
  bottom?: number;
  left?: string;
  right?: string;
  lineHeight?: number;
  center?: boolean;
}>`
  color: ${(props) => (props.color ? `${props.color}` : "rgba(0, 0, 0, 0.5)")};
  font-size: ${(props) => (props.size ? `${props.size}em` : "1em")};
  text-align: ${(props) => (props.position ? `${props.position}` : "left")};
  font-weight: ${(props) => (props.heavy ? `bold` : "normal")};
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  margin-left: ${(props) => (props.left ? `${props.left}` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}` : "0em")};
  line-height: ${(props) => props.lineHeight && `${props.lineHeight}em`};

  // --- For centralization ---
  text-align: ${(props) => props.center && "center"};

  font-family: Mulish;
  font-style: normal;
`;

export const LinkText = styled(Link)<{
  color?: string;
  size?: number;
  position?: string;
  heavy?: boolean;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  lineHeight?: number;
  center?: boolean;
  nounderline?: boolean;
  inline?: boolean;
}>`
  color: ${(props) => (props.color ? `${props.color}` : "rgba(0, 0, 0, 0.5)")};
  font-size: ${(props) => (props.size ? `${props.size}em` : "1em")};
  text-align: ${(props) => (props.position ? `${props.position}` : "left")};
  font-weight: ${(props) => (props.heavy ? `bold` : "normal")};
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  margin-left: ${(props) => (props.left ? `${props.left}em` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}em` : "0em")};
  line-height: ${(props) => props.lineHeight && `${props.lineHeight}em`};
  text-decoration: ${(props) => props.nounderline && "none"};

  // --- For centralization ---
  text-align: ${(props) => props.center && "center"};
  margin-left: ${(props) => props.center && "auto"};
  margin-right: ${(props) => props.center && "auto"};

  font-family: Mulish;
  font-style: normal;
  width: fit-content;
  display: ${(props) => !props.inline && "block"};
`;

export const Avatar = styled.div`
  background: rgba(154, 165, 177, 0.3);
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
`;

export const Divider = styled("hr")<{
  color?: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  size?: number;
}>`
  border: ${(props) => (props.size ? `${props.size}px` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(211, 213, 216, 0.5)")};
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  margin-left: ${(props) => (props.left ? `${props.left}em` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}em` : "0em")};
`;

export const TextField = styled("input")<{
  width?: number;
  height?: number;
  left?: number;
  right?: number;
  size?: number;
  noBorder?: boolean;
}>`
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: ${(props) => (props.left ? `${props.left}em` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}em` : "0em")};
  border: ${(props) => (props.size ? `${props.size}px` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(126, 126, 126, 0.4)")};
  border: ${(props) => props.noBorder && "none"};
  width: ${(props) => (props.width ? `${props.width}em` : "100%")};
  height: ${(props) => (props.height ? `${props.height}em` : "3.5em")};
  outline: none;
  font-family: Mulish;
  font-style: normal;
  // font-weight: 600;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.5);
  &::placeholder {
    padding: 0.2em 0.5em;
  }
`;

export const TextAreaField = styled("textarea")<{
  width?: number;
  height?: number;
  left?: number;
  right?: number;
  size?: number;
}>`
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: ${(props) => (props.left ? `${props.left}em` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}em` : "0em")};
  border: ${(props) => (props.size ? `${props.size}px` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(126, 126, 126, 0.4)")};
  width: ${(props) => (props.width ? `${props.width}em` : "100%")};
  height: ${(props) => (props.height ? `${props.height}em` : "10em")};
  outline: none;
  font-family: Mulish;
  font-style: normal;
  // font-weight: 600;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.5);
  &::placeholder {
    padding: 0.2em 0.5em;
  }
`;

export const SelectField = styled("select")<{
  width?: number;
  height?: number;
  left?: number;
  right?: number;
  size?: number;
  heavy?: boolean;
  background?: string;
  top?: number;
  bottom?: number;
}>`
  border: 1px solid #cbd2d9;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: ${(props) => (props.left ? `${props.left}em` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}em` : "0em")};
  border: ${(props) => (props.size ? `${props.size}px` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(211, 213, 216, 0.5)")};
  background: ${(props) =>
    props.background ? `${props.background}` : "white"};
  padding: 0.5em 0.5em;
  font-weight: ${(props) => (props.heavy ? "bold" : "normal")};
  width: ${(props) => (props.width ? `${props.width}em` : "100%")};
  height: ${(props) => (props.height ? `${props.height}em` : "3.5em")};
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  outline: none;
`;

export const Option = styled("option")<{
  width?: number;
  height?: number;
  left?: number;
  right?: number;
  size?: number;
}>`
  border: 1px solid #cbd2d9;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: ${(props) => (props.left ? `${props.left}em` : "0em")};
  margin-right: ${(props) => (props.right ? `${props.right}em` : "0em")};
  border: ${(props) => (props.size ? `${props.size}px` : "1px")} solid
    ${(props) => (props.color ? `${props.color}` : "rgba(211, 213, 216, 0.5)")};
  width: ${(props) => (props.width ? `${props.width}em` : "100%")};
  height: ${(props) => (props.height ? `${props.height}em` : "3em")};
  outline: none;
`;

export const RedLabel = styled.div`
  background: #fff4f4;
  border: 1px solid #e0b3b2;
  border-radius: 8px;
  width: 100%;
  height: 3em;
  display: flex;
  align-items: center;
  margin-top: 2em;
  justify-content: space-between;
`;

// --- Screen width display control ---
export const ScreenControl = styled("div")<{
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  xs?: boolean;
  fullWidth?: boolean;
}>`
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  display: none;
  display: ${(props) => props.lg && "initial"};
  @media screen and (max-width: 992px) {
    display: none;
    display: ${(props) => props.md && "initial"};
  }
  @media screen and (max-width: 768px) {
    display: none;
    display: ${(props) => props.sm && "initial"};
  }
  @media screen and (max-width: 600px) {
    display: none;
    display: ${(props) => props.xs && "initial"};
  }
`;

export const Main = styled.main`
  width: 100%;
  height: fit-content;
`;

export const YellowBackground = styled("section")<{
  height?: string;
}>`
  height: ${(props) => (props.height ? `${props.height}` : "fit-content")};
  width: 100%;
  background: rgba(242, 145, 27, 0.3);
  text-align: center;
  padding: 0.1px 0px;
  // background-size: 10vh 10vh;
  z-index: -1;
  margin-top: -5.5em;
`;

export const SearchField = styled.div`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  background: white;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  @media screen and (max-width: 992px) {
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    width: 80%;
  }
`;

export const MainSection = styled("section")<{
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  width?: string;
  background?: string;
}>`
  width: ${(props) => (props.width ? `${props.width}` : "90%")};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  margin-left: ${(props) => props.left && `${props.left}`};
  margin-right: ${(props) => props.right && `${props.right}`};
  background: ${(props) => props.background && `${props.background}`};
  height: fit-content;
`;

export const TopHeaderDownSection = styled("section")<{
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  bgImage: string;
}>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.top ? `${props.top}em` : "0em")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}em` : "0em")};
  margin-left: ${(props) => props.left && `${props.left}`};
  margin-right: ${(props) => props.right && `${props.right}`};
  height: 24em;
  background: ${(props) => props.bgImage && `${props.bgImage}`};
  background-size: cover;
  margin-top: -5em;
  padding: 0.1px 0.1px;
  z-index: 100;
`;

// --- I dey vex. I wan build my own background slider sef ---
export const BackgroundSliderAnimate = keyframes`
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
`;

export const BackgroundSlide = styled("div")<{
  bgImage: string;
  bgMobileImage:string;
}>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  background-image: ${(props) => props.bgImage && `url(${props.bgImage})`};
  background-size: 100% 100%;
  z-index: 100;
  //  object-fit: contain;
  -webkit-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
  @media screen and (max-width:769px){
  background-image: ${(props) => props.bgMobileImage && `url(${props.bgMobileImage})`};
  }
`;

export const BackgroundSlider = ({ images,mobileImages }) => {
  const [image, setImage] = useState(images[0]);
  const [image2, setImage2] = useState(mobileImages[0]);

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (counter >= images.length - 1) {
        setCounter(0);
        setImage(images[counter]);
        setImage2(mobileImages[counter])
      } else {
        setCounter(counter + 1);
        setImage(images[counter]);
        setImage2(mobileImages[counter])
      }
    }, 2000);
    // eslint-disable-next-line
  }, [counter]);
  return (
    <>
      <BackgroundSlide bgImage={image} bgMobileImage={image2} />
    </>
  );
};
