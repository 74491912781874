export const allFaqQuests = [
    {
      question: "WHAT IS VIKKYMORE?",
      answer: `Vikkymore is a one-of-a-kind name created by combining the prefix 'Vikky,' which is the CEO's name, with the suffix 'more,' which implies more than one. Vikkymore aims to engage in all parts of life. Vikkymore Exchange, Vikkymore Payment System (VIMEPAY), Vikkymore Agro-mart, Vikkymore Investment, and Vikkymore Foundation are among the company's active sub brands.`,
      category: "VIKKYMORE",
    },
    // {
    //   question: "HOW LEGIT IS VIKKYMORE?",
    //   answer: `Vikkymore Multi-Business Limited is quite legitimate, based on the structure on which Vikkymore is created, the fact that Vikkymore owns more than one brand, and the fact that Vikkymore also gives back to society (i.e Vikkymore foundation). We are as genuine as they come, and our services are always available and excellent. 
    //   `,
    //   category: "VIKKYMORE",
    // },
    // {
    //   question: "IS VIKKYMORE VIMEPAY?",
    //   answer: `The response could be "yes" or "no," with "yes" implying that Vikkymore owns vimepay and "no" implying that vimepay is only a Vikkymore subsidiary. To put it another way, Vikkymore can be Vimepay, but Vikkymore cannot be Vimepay; Vikkymore is Vimepay's parent firm.`,
    //   category: "VIKKYMORE",
    // },
    // {
    //   question: "WHAT IS VIKKYMORE ABOUT?",
    //   answer: `Vimepay is a cryptocurrency trading platform operated by Vikkymore Multi-Business Limited. Vimepay is an online financial escrow system that promises to provide the finest cryptocurrency trading experience to its customers. It enables its users to perform cryptocurrency transactions. You may buy bitcoin using vimepay, trade bitcoin with vimepay, and even store bitcoin in your Vimepay online wallet. To get started, go to www.vimepay.com.`,
    //   category: "VIKKYMORE",
    // },
    // {
    //   question: "WHAT IS DDXPRESS ABOUT?",
    //   answer: `DDxpress is a brand under Vikkymore Multi-Business Limited that provides logistics services. Contact DDxpress on instagram @dd_xpress_delivery. 
    //   `,
    //   category: "VIKKYMORE",
    // },
    {
      question: "WHERE IS VIKKYMORE LOCATED?",
      answer: `Vikkymore Multi-Business Limited is located at No 18, University Crescent Road, Oba Olagbegi Estate, Old Bodija. Ibadan, Oyo State.`,
      category: "VIKKYMORE",
    },
    {
      question: "HOW CAN I USE YOUR SERVICES? ",
      answer: `You can visit the pages and websites of every services offered by Vikkymore Multi-Business limited by clicking the links below:
                <br/>
                    <a href="https://www.vimepay.com">www.vimepay.com</a>
                <br/>
                <a href="https://www.vikkymoreagro.com">www.vikkymoreagro.com</a>
                <br/>
                <a href="https://vikkymoreinvest.com">www.vikkymoreinvest.com</a>
                <br/>
                <a href="https://www.vkkymorefoundation.com">www.vkkymorefoundation.com</a>
                <br/>
                <a href="https://www.ddxpress.com">www.ddxpress.com</a>
              `,
      category: "VIKKYMORE",
    },
  
  
    {
      question: `WHAT CAN I TRADE ON VIMEPAY?`,
      answer: `The base currency on Vimepay is BITCOIN. However, we have other cryptocurrencies listed (DASH, litecoin, Bitcoin cash, bitcoin sv).    `,
      category: `VIMEPAY`,
    },
    {
      question: `WHAT DOES ESCROW MEAN?
    `,
      answer: `An escrow system is one of the most secure ways of buying and selling your cryptocurrencies. It is a financial agreement in which a transaction between two parties is regulated by a third party who holds the paymentn in an escrow wallet. The payment is not released until all terms of the transaction are met by both parties.   
    `,
      category: `VIMEPAY`,
    },
    {
      question: `HOW MUCH DOES VIMEPAY CHARGE FOR TRADING?
    `,
      answer: `Vimepay charges a very low fee of 0.1% for every trade on her website.
    `,
      category: `VIMEPAY`,
    },
    {
      question: `WHAT IS A WALLET ADDRESS?
    `,
      answer: `A wallet address is a randomly generated set of numbers and letters. This set usually consists of 26 to 35 alphanumeric characters. A wallet address is ideally a one-time link generated by a wallet. Wallet addresses are needed to send or receive digital assets.`,
      category: `VIMEPAY`,
    },
    {
      question: `HOW TO CREATE OR GENERATE A WALLET ON VIMEPAY?`,
      answer: `Vimepay provides you with the option of creating a wallet of your choice from various coin options (Bitcoin, Litecoin, Dash, Bitcoin Cash, Bitcoin SV).
                Go to the navigation bar on the left side of your vimepay account homepage
                <br/>
                Click on view wallet from the WALLET section 
                <br/>
                Click on add account 
                <br/>
                Select from the drop-down of coin wallet (bitcoin, litecoin, bitcoin cash, dash, bitcoin SV) 
                <br/>
                If you select bitcoin and click on add. A bitcoin wallet will be created for your deposits and withdrawal. The same goes for the other coins.
              `,
      category: `VIMEPAY`,
    },
    {
      question: `WHAT CAN I DO WITH MY VIMEPAY WALLET`,
      answer: `You can deposit bitcoin or other cryptocurrency in your vimepay wallet.
    `,
      category: `VIMEPAY`,
    },
    {
      question: `IS VIMEPAY MOBILE-FRIENDLY? WHY IS MY ANDROID NOT WORKING?`,
      answer: `Yes, Vimepay is mobile-friendly. You can browse through the Vimepay website swiftly on an Android or iPhone browser. `,
      category: `VIMEPAY`,
    },
    {
      question: `CAN I BUY BTC ON VIMEPAY AND TRANSFER IT TO ANOTHER PLATFORM’S WALLET?
    `,
      answer: `Yes, you can. All you need to do is collect the person's wallet address and transfer the BTC from your vimepay wallet.
    `,
      category: `VIMEPAY`,
    },
    {
      question: `HOW DO I MESSAGE THE CUSTOMER SERVICE?`,
      answer: `To message our customer support, kindly send a message to 08122450145`,
      category: `VIMEPAY`,
    },
  
    {
      question: `WHAT DOES VIKKYMORE EXCHANGE OFFER?
    `,
      answer: `Vikkymore exchange provides a smooth means of exchange. You can easily trade what you have (tangible assets) for what you want.
    `,
      category: `VIKKYMORE EXCHANGE`,
    },
    {
      question: `IS VIKKYMORE EXCHANGE A PONZI SCHEME?`,
      answer: `No. It is not a ponzi scheme.
    `,
      category: `VIKKYMORE EXCHANGE`,
    },
    {
      question: `HOW DO I MESSAGE THE CUSTOMER SERVICE?`,
      answer: `To message our customer support, kindly send a message to 08122450145`,
      category: `VIKKYMORE EXCHANGE`,
    },
  
    {
      question: `IS  VIKKYMORE INVESTMENT REAL?`,
      answer: `Yes. It is. However, it is for Vikkymore’s trusted clients. `,
      category: `VIKKYMORE INVESTMENT`,
    },
    {
      question: `WHAT CAN I USE TO INVEST IN VIKKYMORE INVESTMENT?`,
      answer: `You can invest with us using fiat currency or cryptocurrencies. All you have to do is pick the best investment option available and invest.`,
      category: `VIKKYMORE INVESTMENT`,
    },
    {
      question: `DOES VIKKYMORE INVESTMENT INVOLVE REFERRAL?`,
      answer: `No, it does not. The system is organized to suit loyal Vikkymore multi-business limited clients. For more enquiries, contact <a href="mailto:vikkymoreee@gmail.com">Support</a>.
    `,
      category: `VIKKYMORE INVESTMENT`,
    },
    {
      question: `DOES VIKKYMORE MULTI-BUSINESS LIMITED ALLOW INVESTORS TO BUY SHARES?
    `,
      answer: `Yes, you can buy shares, but you will have to message the <a href="mailto:vikkymoreee@gmail.com">support</a> team to put you through how to go about it.`,
      category: `VIKKYMORE INVESTMENT`,
    },
    {
      question: `HOW DO I MESSAGE THE CUSTOMER SERVICE?
    `,
      answer: `To message our customer support, kindly send a message to 08122450145
    `,
      category: `VIKKYMORE INVESTMENT`,
    },
  
    {
      question: `WHAT ARE THE ITEMS AVAILABLE AT  VIKKYMORE AGROMART?
    `,
      answer: `Vikkymore Agromart is an accessible market for customers to get maize, plantain, cocoa and other cash crops at affordable prices. 
    `,
      category: `VIKKYMORE AGROMART`,
    },
  
    {
      question: `DOES DDXPRESS WORK ON SUNDAYS?`,
      answer: `DDXPRESS works 9:00 am to 6:00 pm, Mondays to Saturdays, with the exception of religious holidays.`,
      category: `DDXPRESS`,
    },
    {
      question: `DOES DDXPRESS PICKUP ITEMS FROM OUTSIDE IBADAN?
    `,
      answer: `No, DDXPRESS is solely and strategically available for customers to pick up items in and around the city of Ibadan.`,
      category: `DDXPRESS`,
    },
    {
      question: `DOES DDXPRESS DELIVER PACKAGES OUTSIDE IBADAN?`,
      answer: `Not at all. DDXPRESS only delivers packages inside Ibadan. DDXPRESS knows the city of Ibadan like the map itself, and her responsibility of delivering packages for customers in Ibadan is her priority.`,
      category: `DDXPRESS`,
    },
    {
      question: `DOES DDXPRESS HELP WITH PACKAGING GOODS?`,
      answer: `DDXPRESS does not package goods. The prominent services of DDXPRESS include picking up packages for customers within Ibadan, delivering packages within Ibadan and going on errands for customers.`,
      category: `DDXPRESS`,
    },
    {
      question: `DOES DDXPRESS COLLECT CASH?`,
      answer: `No. DDXPRESS accepts a transfer from any bank in Nigeria, and with a screenshot of payment, your delivery or pickup is initiated.
    `,
      category: `DDXPRESS`,
    },
    {
      question: `ARE DDXPRESS PICK UP AND DELIVERY FEES FIXED?`,
      answer: `No, our price is not fixed. DDXPRESS is available for pickups and deliveries during her working hours, and charges depend on the distance.`,
      category: `DDXPRESS`,
    },
  
    {
      question: `WHEN DOES VIKKYMORE FOUNDATION GO FOR OUTREACH?`,
      answer: `Our foundation is in charge of reaching out to the less privileged and supporting them in different ways that we can. We go for our outreach every last Saturday of the month.`,
      category: `VIKKYMORE FOUNDATION`,
    },
    {
      question: `DOES VIKKYMORE FOUNDATION TAKE CHILDREN?`,
      answer: `No. We empower orphanages and homes that are dedicated to helping orphans,the disabled, and other children who need help. We can help sponsor a kid to school and render humanity service to these homes and orphanages.`,
      category: `VIKKYMORE FOUNDATION`,
    },
    {
      question: `HOW DO I MESSAGE THE CUSTOMER SERVICE?`,
      answer: `To message our customer support, kindly send a message to 08122450145`,
      category: `VIKKYMORE FOUNDATION`,
    },
  ];