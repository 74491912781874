import styled, { keyframes } from "styled-components";

export const HeaderHeader = styled("header") <{
  state?: boolean;
}>`
  width: 100%;
  min-height: 5em;
  height:fit-content;
  background: ${(props) => (props.state ? "transparent" : "white")};
  z-index: 10000000000000000;
  position: fixed;
  top:0;
  // border-bottom: 0.05em solid rgba(5, 5, 5, 0.1);
`;

export const HeaderLogo = styled.div`
  color: #f2911b;
  background: #f2911b;
  width: 5em;
  height: 3em;
  // position: absolute;
`;

export const dropNavBar = keyframes`
from{
  height:0em;
  opacity:0;
}
to{
  height:11em;
  opacity:1
}`;

export const removeNavBar = keyframes`
from{
  height:11em;
  opacity:1;
}
to{
  height:1em;
  opacity:0;
  overflow:none;
}`;

export const LayoutHeaderDropDown = styled("div") <{
  state?: boolean;
}>`
  width: 100%;
  // background: white;
  height: 100%;
  z-index: 10000000000000000;
  // height: ${(props) => (props.state ? "11em" : "0.5em")};
  box-shadow: ${(props) => props.state && "0px 4px 4px rgba(0, 0, 0, 0.1)"};
  animation: 0.5s ${(props) => (props.state ? dropNavBar : removeNavBar)};
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const LayoutMobile = styled("div") <{
  state?: boolean;
}>`
  text-align: center;
  background: white;
  height: fit-content;
  animation: 0.5s ${dropNavBar};
  display: ${(props) => (props.state ? "block" : "none")};
`;
