import { Grid } from "@material-ui/core";
import React from "react";
import {
  Button,
  Card,
  TextAreaField,
  TextField,
} from "../../styles/util/UtilStyles";

const ContactBox = () => {
  return (
    <>
      <Card
        // height={10}
        top={"3em"}
        shadow
        WidthResponsive
        lgWidth={"60%"}
        mdWidth={"70%"}
        smWidth={"90%"}
        xsWidth={"90%"}
        radius={0.2}
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
      >
        {/* <Grid grid spacing={1} cardWidth={"90%"} top={"5em"} left={"1em"} right={"1em"}>
          <Grid lg={12} md={6} sm={6} xs={12}>
            <TextField placeholder="Your Full Name*" />
          </Grid>
          <Grid lg={6} md={6} sm={6} xs={12}>
            <TextField placeholder="Your Email Address*" />
          </Grid>
          <Grid lg={6} md={6} sm={6} xs={12}>
            <TextFieldi placeholder="Your Email Address*" />
          </Grid>
        </Grid> */}

        <div
          style={{
            marginTop: "2em",
            marginLeft: "1em",
            marginRight: "1em",
            marginBottom: "2em",
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField placeholder="Your Full Name*" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField placeholder="Your Email Address*" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField placeholder="Phone No*" />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAreaField placeholder="Your Message*" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Button background={"#F2911B"}>Submit</Button>
            </Grid>
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default ContactBox;
