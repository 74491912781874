import { Grid } from "@material-ui/core";
import { ContactSection } from "../../styles/home/HomeStyles";

import {
  Button,
  Card,
  ScreenControl,
  Text,
  TextAreaField,
  TextField,
  TitleText,
} from "../../styles/util/UtilStyles";

const HomeContactUs = () => {
  return (
    <ContactSection
      data-aos="zoom-in"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
    >
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} xs={6} sm={6}>
          <ScreenControl lg md sm>
            <Card
              background={"transparent"}
              left={"2em"}
              top={"15em"}
              right={"2em"}
            >
              <TitleText lineHeight={1.5} color={"white"} heavy>
                HOW MAY WE SERVE YOU BETTER?
              </TitleText>
              <Text color={"rgba(255,255,255,0.8)"} lineHeight={2} top={2}>
                Do you have questions about our services?
                <br />
                Vikkymore multi-business limited is always available to take your complaints and enquiries.

              </Text>
            </Card>
          </ScreenControl>
        </Grid>
        <Grid item lg={6} md={6} xs={12} sm={6}>
          <Card
            shadowAlt={"0px 8px 15px rgba(77, 40, 0, 0.06)"}
            background={"white"}
            left={"1.5em"}
            right={"1.5em"}
            top={"1.5em"}
            bottom={"1.5em"}
          >
            <Card left={"2em"} right={"2em"} top={"2em"} bottom={"2em"}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField placeholder="Your Full Name*" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField placeholder="Your Email Address*" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField placeholder="Phone No*" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextAreaField placeholder="Your Message*" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Button background={"#F2911B"}>Send message</Button>
                </Grid>
              </Grid>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </ContactSection>
  );
};

export default HomeContactUs;
