import { useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { OurServices, ServicesBgLayer } from "../../components/services";

const OurServicesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout pageTitle={"Our Services"}>
        <ServicesBgLayer />

        <OurServices />
      </Layout>
    </>
  );
};

export default OurServicesPage;
