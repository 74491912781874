import { Grid } from "@material-ui/core";
import {
  Card,
  MainSection,
  ScreenControl,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import aboutHumanImg from "../../assets/aboutus/Our-Vision.png";
import aboutVisionImg from "../../assets/aboutus/Our-Vision.png";
import aboutMissionImg from "../../assets/aboutus/Our-Mission.png";
import aboutStoryImg from "../../assets/aboutus/About-Our-Story.png";
import aboutWhatImg from "../../assets/aboutus/About-what.png";

import { Circle } from "../../styles/util/icons";
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import { VerifiedUserRounded } from "@material-ui/icons";

const businessGoals = [
  {
    title: "Affordability",
    text: "We have made it our duty to provide affordable services to everybody including the low earners and give them the opportunity to make a living with little",
    icon: (
      <MonetizationOnTwoToneIcon
        style={{ marginLeft: "auto", marginRight: "auto" }}
      />
    ),
  },
  {
    title: "Reliability",
    text: "Our brands can be trusted and relied on to provide quality services and deliver nothing but the best.",
    icon: (
      <VerifiedUserRounded
        style={{ marginLeft: "auto", marginRight: "auto" }}
      />
    ),
  },
  {
    title: "Ingenuity",
    text: "We have a creative team that puts the customer in mind and provides original products that would serve individual needs and help Nigeria to grow economically.",
    icon: (
      <RoomServiceIcon style={{ marginLeft: "auto", marginRight: "auto" }} />
    ),
  },
];

const AboutUsMiddleView = () => {
  return (
    <>
      <MainSection
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        width={"80%"}
        top={2}
      >
        <Grid
          container
          spacing={4}
          style={{ marginTop: "0em", marginBottom: "2em", display: "none" }}
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
        >
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Card top={"2em"}>
              <TitleText heavy>Our Founder</TitleText>
              <Text lineHeight={2} top={1}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.`
              </Text>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Card>
              <img
                style={{ width: "100%", height: "100%" }}
                src={aboutHumanImg}
                alt=""
              />
            </Card>
            <Text lineHeight={2} size={1.2} heavy>
              Adrik Pushkin
            </Text>
            <Text>CEO & Co-Founder</Text>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          style={{ marginTop: "2em", marginBottom: "2em" }}
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card>
              <img
                style={{ width: "100%", height: "100%" }}
                src={aboutStoryImg}
                alt=""
              />
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"2em"}>
              <TitleText heavy>Our Story</TitleText>
              <Text top={1} size={0.9} lineHeight={2}>
                <p>
                  Vikkymore is a company with 6 unique brands that were founded
                  to be the solution to the economic problems Nigerians are
                  facing. The company was established in 2018 when the founder,
                  Victor Olaoye relocated to Ibadan, the largest city by
                  geographical area and third-largest city by population, and
                  noticed that the people staying in Ibadan had little or no
                  access to affordable services and opportunities for financial
                  growth.
                </p>
              </Text>
              <Text top={1} size={0.9} lineHeight={2}>
                <p>
                  The company was established with the aim of providing the
                  people with MORE than they have: by introducing digital assets
                  to the people and an opportunity to invest, and from there the
                  agricultural, logistics, trading, and exchange branches of the
                  company were created.
                </p>
              </Text>
              <Text lineHeight={2} top={1} size={0.9}>
                <p>
                  Since inception, we have expanded to other parts of the
                  country and have been able to achieve some of our goals which
                  include:
                  <br />
                  <li>
                    Creating employment opportunities for the people in the
                    areas in which we operate.
                  </li>
                  <li>
                    Enlightening people about digital assets and providing a
                    good platform for them to invest.
                  </li>
                  <li>
                    Providing affordable services for the people in the areas in
                    which we operate.
                  </li>
                  <li>
                    Reducing the food scarcity in the country through
                    agriculture
                  </li>
                  <li>
                    Supporting the less privileged as part of our corporate
                    social responsibility.
                  </li>
                </p>
              </Text>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          style={{ marginTop: "2em", marginBottom: "2em" }}
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl sm xs>
              <Card center>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={aboutWhatImg}
                  alt=""
                />
              </Card>
            </ScreenControl>
            <Card top={"2em"}>
              <TitleText heavy>What Makes Us Different.</TitleText>
              <Text lineHeight={2} top={1} size={0.9}>
                <p>
                  <li>
                    Affordability: We have made it our duty to provide
                    affordable services to everybody including the low earners
                    and give them the opportunity to make a living with little.
                  </li>
                  <li>
                    Reliability: Our brands can be trusted and relied on to
                    provide quality services and deliver nothing but the best.
                  </li>
                  <li>
                    Ingenuity: We have a creative team that puts the customer in
                    mind and provides original products that would serve
                    individual needs and help Nigeria to grow economically.
                  </li>
                </p>
              </Text>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl lg md>
              <Card center>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={aboutWhatImg}
                  alt=""
                />
              </Card>
            </ScreenControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          style={{ marginTop: "2em", marginBottom: "2em" }}
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card>
              <img
                style={{ width: "100%", height: "100%" }}
                src={aboutVisionImg}
                alt=""
              />
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"2em"}>
              <TitleText heavy>Our Vision</TitleText>
              <Text lineHeight={2} top={1} size={0.9}>
                <i>
                  “Financial freedom is available to those who learn about it
                  and work for it.” ― Robert Kiyosaki
                </i>
                <p>
                  Our vision is to lead Nigerians to financial freedom, become
                  pioneers of the first Nigerian Crypto Bank, and be the go-to
                  company for affordable services.
                </p>
              </Text>
            </Card>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={4}
          style={{ marginTop: "2em", marginBottom: "2em" }}
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl sm xs>
              <Card center>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={aboutMissionImg}
                  alt=""
                />
              </Card>
            </ScreenControl>
            <Card top={"2em"}>
              <TitleText heavy>Our Mission</TitleText>
              <Text lineHeight={2} top={1} size={0.9}>
                <p>
                  Vikkymore Multi-business Limited seeks to create a better life
                  for Nigerians by providing affordable services to the people
                  and enlightening them about the financial opportunities
                  available to them. At Vikkymore MBL, we believe in providing
                  MORE and exceeding our customer’s expectations.
                </p>

                <p>Our objectives include:</p>
                <li>
                  To exceed our customers’ expectations and bring the best value
                  to consumers at a low cost.{" "}
                </li>
                <li>To provide job opportunities to Nigerians. </li>
                <li>
                  To become a leading crypto trading company and the first
                  crypto bank in Nigeria.
                </li>
                <li>To become a leading distributor of farm produce.</li>
                <li>
                  To promote convenience for our clients and establish customer
                  satisfaction through our brands.
                </li>
                <li>
                  To provide efficient services in financial management,
                  investment and advisory.
                </li>
                <li>
                  To be socially responsible in the communities where we
                  operate.
                </li>
                <li>
                  To promote and establish subsidiary companies for business
                  expansion.
                </li>
                <li>To become change agents in our country, Nigeria.</li>
              </Text>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl lg md>
              <Card center>
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={aboutMissionImg}
                  alt=""
                />
              </Card>
            </ScreenControl>
          </Grid>
        </Grid>
      </MainSection>

      <MainSection top={5}>
        <Card bottom={"3em"}>
          <TitleText heavy>
            Our approach to reach your
            <br />
            business goals
          </TitleText>
        </Card>

        <Grid container spacing={4}>
          {businessGoals.map((item, pos) => (
            <Grid
              key={pos}
              data-aos="zoom-in"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"
              item
              lg={4}
              md={4}
              sm={4}
              xs={6}
            >
              <Card textAlign>
                <Circle style={{ marginLeft: "auto", marginRight: "auto" }}>
                  {item?.icon}
                </Circle>
                <TitleText lineHeight={2} size={1.2} center heavy>
                  {item?.title}
                </TitleText>
                <Text size={0.9} lineHeight={1.5} center>
                  {item?.text}
                </Text>
              </Card>
            </Grid>
          ))}
        </Grid>
      </MainSection>
    </>
  );
};

export default AboutUsMiddleView;
