import { useState } from "react";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import { FaqsEachSegment, FaqsSection } from "../../styles/faqs/FaqsStyles";
import { Circle } from "../../styles/util/icons";
import { Card, Text, TitleText } from "../../styles/util/UtilStyles";
import { FaqOpenTypes, FaqQuestsTypes } from "../../types/FaqTypes";

const FaqsQuestions = (props: FaqQuestsTypes) => {
  const [open, setOpen] = useState<FaqOpenTypes>({});

  const dropDown = (key: number) => {
    setOpen({
      [key]: open?.[key] ? !open?.[key] : true,
    });
  };

  const { faqs } = props;

  return (
    <>
      <Card>
        <FaqsSection>
          {faqs.map((item, pos) => (
            <FaqsEachSegment
              key={pos}
              data-aos="zoom-in"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"
            >
              <Card
                bottom={"1.5em"}
                onClick={() => dropDown(pos)}
                flex
                spaceBetween
              >
                <TitleText size={0.9} bottom={1} heavy>
                  <li>{item?.question}</li>
                </TitleText>
                <Circle width={"2em"} height={"2em"} cursor={true}>
                  {!open[pos] ? (
                    <ArrowDropDownIcon
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                    />
                  )}
                </Circle>
              </Card>

              {open[pos] && (
                <Text
                  lineHeight={2}
                  size={0.85}
                  bottom={1}
                  dangerouslySetInnerHTML={{ __html: item?.answer }}
                />
              )}
            </FaqsEachSegment>
          ))}
        </FaqsSection>
      </Card>
    </>
  );
};

export default FaqsQuestions;
