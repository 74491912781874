import { useEffect } from "react";

import Layout from "../../components/layout/Layout";
import {
  AboutProduct,
  OtherServices,
  TopBackgroundView,
} from "../../components/products";

import bgImage from "../../assets/products_bg/HEADERS-DD-Xpress.png";
import firstImage from "../../assets/sliders/SLIDER-DD-Xpress-Delivery.png";
import secondImage from "../../assets/bg/ddXpressBg.png";

const aboutService = `
  DD XPRESS is a logistics company located in Ibadan, Nigeria. We provide affordable pickup and delivery services to individuals, groups, and businesses within Ibadan, Nigeria. With a team of well-trained and respectful dispatch riders and other team members, we pride ourselves in being one of the best logistics companies in Ibadan with over 100 businesses using our services full time. 
  <br/>
  Our Services include:
  <p>
  <li>
  Picking up packages for customers within Ibadan.
  </li>
  <li>
  Delivering packages within Ibadan.
  </li>
  <li>
  Going on errands for customers.
  </li>
  <br/>
  To book our services, click on the WhatsApp link below to chat with a responsive customer service agent or visit our Instagram page. 
  `;

const DDXpressPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout pageTitle={"Vikkymore - DD Xpress"}>
        <TopBackgroundView
          bgImage={`linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,1)), url(${bgImage})`}
          productName={"DD Xpress Delivery"}
        />

        <AboutProduct
          aboutService={aboutService}
          url={"https://wa.me/message/KKLYEPEYAXF3F1"}
          urlType={"whatsapp"}
          firstImg={firstImage}
          secondImg={secondImage}
        />

        <OtherServices />
      </Layout>
    </>
  );
};

export default DDXpressPage;
