import { Grid } from "@material-ui/core";
import { ServicesSection } from "../../styles/home/HomeStyles";
import ServiceCard from "./ServiceCard";

const ourServices = [
  {
    logo: "",
    name: "DD Xpress Delivery",
    preText: "1,000+ active users",
    text: "Logistics on the go, anywhere in ibadan.",
    link: "/products/dd-xpress/",
  },
  {
    logo: "",
    name: "Vimepay",
    preText: "5,000+ active users",
    text: "Buy, sell and store cryptocurrency anytime and anywhere.",
    link: "/products/vimepay/",
  },
  {
    logo: "",
    name: "Vikkymore Exchange",
    preText: "1,000+ active users",
    text: "Easily exchange what you have for what you want.",
    link: "/products/vikky-exchange/",
  },
  {
    logo: "",
    name: "Vikkymore Foundation",
    preText: "1,000+ active users",
    text: "Giving back to the society and supporting the needy. ",
    link: "/products/vikky-foundation/",
  },
  {
    logo: "",
    name: "Vikkymore Agromart",
    preText: "1,000+ active users",
    text: "Cultivating farm produce and providing agricultural services.",
    link: "/products/agromart/",
  },
  {
    logo: "",
    name: "Vikkymore Investment",
    preText: "1200+ active users",
    text: "Securing your future through financial freedom.",
    link: "/products/vikky-investment/",
  },
];

const ServicesListCard = () => {
  return (
    <ServicesSection>
      <Grid container spacing={4}>
        {ourServices.map((item, pos) => (
          <Grid key={pos} item lg={4} md={4} sm={6} xs={12}>
            <ServiceCard details={item} pos={pos} />
          </Grid>
        ))}
      </Grid>
    </ServicesSection>
  );
};

export default ServicesListCard;
