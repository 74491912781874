import {
  MainSection,
  TitleText,
  TopHeaderDownSection,
} from "../../styles/util/UtilStyles";
import { ITopViewProps } from "../../types/ProductsTypes";
// import ddXpressImage from "../../assets/image right.png";

const TopBackgroundView = (props: ITopViewProps) => {
  const { productName } = props;
  return (
    <>
      <TopHeaderDownSection
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        {...props}
      >
        <MainSection>
          <TitleText top={10} color={"white"} heavy>
            {productName}
          </TitleText>
        </MainSection>
      </TopHeaderDownSection>
    </>
  );
};

export default TopBackgroundView;
