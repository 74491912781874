import { Grid } from "@material-ui/core";
import {
  Button,
  Card,
  MainSection,
  ScreenControl,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import ddXImage from "../../assets/sliders/SLIDER-DD-Xpress-Delivery.png";
import xChangeImage from "../../assets/sliders/SLIDER-Vikkymore-Exchange.png";
import vimepayImage from "../../assets/sliders/SLIDER-Vimepay.png";
import investImage from "../../assets/sliders/SLIDER-VimeVest.png";
// --dummy for now ---
import foundationImage from "../../assets/sliders/SLIDER-Vikkymore-Foundation.png";
import agromartImage from "../../assets/sliders/SLIDER-Vikkymore-Agromart.png";
import { useHistory } from "react-router-dom";

const OurServices = () => {
  const history = useHistory();

  return (
    <>
      <MainSection
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
        top={5}
      >
        <TitleText lineHeight={2} center heavy>
          OUR SERVICES
        </TitleText>
        <Text center lineHeight={2}>
          We have 6 unique brands that offer affordable and quality services
          that will exceed your expectations because we do MORE.
        </Text>

        <Grid
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
          style={{ marginTop: "3em" }}
          container
          spacing={4}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card>
              <img
                src={ddXImage}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Card>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"2em"}>
              <TitleText size={1.5} lineHeight={2} heavy>
                DD Xpress Delivery
              </TitleText>
              <Text lineHeight={2}>
                DD XPRESS is a logistics company located in Ibadan, Nigeria. We
                provide affordable pickup and delivery services to individuals,
                groups, and businesses within Ibadan, Nigeria. With a team of
                well-trained and respectful dispatch riders and other team
                members, we pride ourselves in being one of the best logistics
                companies in Ibadan with over 100 businesses using our services
                full time.
              </Text>
              <Button
                onClick={() => history.push("/products/dd-xpress/")}
                top={2}
                width={11}
                background={"#f2911b"}
              >
                View Website
              </Button>
            </Card>
          </Grid>
        </Grid>

        <Grid
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
          style={{ marginTop: "3em" }}
          container
          spacing={4}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl xs sm>
              <Card>
                <img
                  src={xChangeImage}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Card>
            </ScreenControl>
            <Card top={"2em"}>
              <TitleText size={1.5} lineHeight={2} heavy>
                Vikkymore Exchange
              </TitleText>
              <Text lineHeight={2}>
                Vikkymore exchange is an arm of Vikkymore Multi-business Limited
                that handles the exchange of goods and services including
                digital currency and fiat currency. With Vikkymore exchange you
                can easily trade what you have (tangible assets) for what you
                want.
              </Text>
              <Button
                onClick={() => history.push("/products/vikky-exchange/")}
                top={2}
                width={11}
                background={"#f2911b"}
              >
                View Website
              </Button>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl lg md>
              <Card>
                <img
                  src={xChangeImage}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Card>
            </ScreenControl>
          </Grid>
        </Grid>

        <Grid
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
          style={{ marginTop: "3em" }}
          container
          spacing={4}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card>
              <img
                src={vimepayImage}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"2em"}>
              <TitleText size={1.5} lineHeight={2} heavy>
                Vimepay
              </TitleText>
              <Text lineHeight={2}>
                In a bid to make Nigeria and Africa meet up with the digital
                advancement in the first world countries, Vikkymore came up with
                a solution and that solution is Vimepay. Vimepay is a subsidiary
                of Vikkymore Multi-business Ltd, with its headquarters in
                Ibadan, Nigeria. With the support of one of the best teams,
                Vimepay is gradually reaching its goal of becoming one of the
                best platforms where anybody, no matter where you are, can trade
                Bitcoin with ease.
                <br />
                Vimepay is an online financial escrow based platform that allows
                you to safely buy, sell and store cryptocurrencies anywhere you
                are
              </Text>
              <Button
                onClick={() => history.push("/products/vimepay/")}
                top={2}
                width={11}
                background={"#f2911b"}
              >
                View Website
              </Button>
            </Card>
          </Grid>
        </Grid>

        <Grid
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
          style={{ marginTop: "3em" }}
          container
          spacing={4}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl xs sm>
              <Card>
                <img
                  src={investImage}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Card>
            </ScreenControl>
            <Card top={"2em"}>
              <TitleText size={1.5} lineHeight={2} heavy>
                Vikkymore Investment
              </TitleText>
              <Text lineHeight={2}>
                To become financially independent, people need to have access to
                good investment opportunities and that’s what we thought of when
                we created Vikkymore Investment, an investment arm of the
                company that gives mouth-watering interest rates to our
                long-term customers.
              </Text>
              <Button
                onClick={() => history.push("/products/vikky-investment/")}
                top={2}
                width={11}
                background={"#f2911b"}
              >
                View Website
              </Button>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl lg md>
              <Card>
                <img
                  src={investImage}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Card>
            </ScreenControl>
          </Grid>
        </Grid>

        <Grid
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
          style={{ marginTop: "3em" }}
          container
          spacing={4}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card>
              <img
                src={foundationImage}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"2em"}>
              <TitleText size={1.5} lineHeight={2} heavy>
                Vikkymore Foundation
              </TitleText>
              <Text lineHeight={2}>
                One of our goals as an organization is to be change agents in
                the community where we operate and we do this by participating
                in Corporate Social Responsibilities. Our foundation is in
                charge of reaching out to the less privileged and supporting
                them in different ways that we can. We have visited a number of
                Orphanages since inception and have supported many through our
                foundation.
              </Text>
              <Button
                onClick={() => history.push("/products/vikky-foundation/")}
                top={2}
                width={11}
                background={"#f2911b"}
              >
                View Website
              </Button>
            </Card>
          </Grid>
        </Grid>

        <Grid
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="500"
          style={{ marginTop: "3em" }}
          container
          spacing={4}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl xs sm>
              <Card>
                <img
                  src={agromartImage}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Card>
            </ScreenControl>
            <Card top={"2em"}>
              <TitleText size={1.5} lineHeight={2} heavy>
                Vikkymore Agromart
              </TitleText>
              <Text lineHeight={2}>
                Vikkymore Agromart is the agricultural arm of Vikkymore
                Multi-business Limited where we produce both cash and food crops
                to support the livelihood of the people and also help in the
                economic growth of the country.
              </Text>
              <Button
                onClick={() => history.push("/products/agromart/")}
                top={2}
                width={11}
                background={"#f2911b"}
              >
                View Website
              </Button>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ScreenControl md lg>
              <Card>
                <img
                  src={agromartImage}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Card>
            </ScreenControl>
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default OurServices;
