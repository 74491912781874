import { Grid } from "@material-ui/core";
import {
  Button,
  Card,
  LinkText,
  MainSection,
  Text,
  TitleText,
} from "../../styles/util/UtilStyles";

import aboutServicesImage from "../../assets/aboutus/Our-Services.png";
// import { Box } from "../../styles/util/icons";

const AboutUsServices = () => {
  return (
    <>
      <MainSection
        top={5}
        data-aos="zoom-in"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="500"
      >
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card>
              <img
                src={aboutServicesImage}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card top={"2em"}>
              <TitleText lineHeight={2} heavy>
                Our Services
              </TitleText>
              <Text lineHeight={2}>
                Specially developed to help restore a more youthful appearance
                with improved radience and bounce.
              </Text>

              <Card top={"2em"}>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    {/* <Box></Box> */}
                  </Grid>
                  <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Text color={"rgba(5,5,5,0.9)"}>DD Xpress Delivery</Text>
                    <Text lineHeight={2}>
                      Logistics on the go, anywhere in ibadan.
                    </Text>
                  </Grid>
                </Grid>
              </Card>

              <Card top={"2em"}>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    {/* <Box></Box> */}
                  </Grid>
                  <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Text color={"rgba(5,5,5,0.9)"}>Vimepay</Text>
                    <Text lineHeight={2}>
                      Buy, sell and store cryptocurrency anytime and anywhere.
                    </Text>
                  </Grid>
                </Grid>
              </Card>

              <LinkText to="/our-services/" nounderline>
                <Button top={3} background={"rgba(242, 145, 27, 1)"} width={11}>
                  View All
                </Button>
              </LinkText>
            </Card>
          </Grid>
        </Grid>
      </MainSection>
    </>
  );
};

export default AboutUsServices;
