import styled from "styled-components";

export const FooterFooter = styled.footer`
  width: 100%;
  margin-top: 5em;
  height: fit-content;
  background: #ffffff;
`;

export const FooterInner = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 85%;
  }
`;
