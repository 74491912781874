import Layout from "../../components/layout/Layout";
import {
  AboutProduct,
  OtherServices,
  TopBackgroundView,
} from "../../components/products";

import xChangeImage from "../../assets/products_bg/HEADERS-Exchange.png";
import firstImage from "../../assets/sliders/SLIDER-Vikkymore-Exchange.png";
import { useEffect } from "react";

const aboutService = `
Vikkymore exchange is an arm of Vikkymore Multi-business Limited that handles the exchange of goods and services including digital currency and fiat currency. With Vikkymore exchange you can easily trade what you have (tangible assets) for what you want.
<p>
Exchange your cryptocurrency and gift cards with Vikkymore by clicking button below.
</p>
`;

const VikkyExchangePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout pageTitle={"Vikkymore - Vikky Exchange"}>
        <TopBackgroundView
          bgImage={`linear-gradient(0deg, rgba(255,255,255,0), rgba(255,255,255,1)), url(${xChangeImage})`}
          productName={"Vikky Exchange"}
        />

        <AboutProduct
          firstImg={firstImage}
          aboutService={aboutService}
          url={"https://paxful.com/offer/qNL1YAqdQ1n"}
          urlType="website"
        />

        <OtherServices />
      </Layout>
    </>
  );
};

export default VikkyExchangePage;
